import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentLink"] */ "/vercel/path0/components/jbda/cookies-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDAction"] */ "/vercel/path0/components/jbda/dd/dd-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/jbda/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section"] */ "/vercel/path0/components/jbda/section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
